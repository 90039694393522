import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout fullWidth>
    <section className="hero is-warning is-medium has-text-centered">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            Not Found
          </h1>
          <h2 className="subtitle">
            Use navigation, please
          </h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage
